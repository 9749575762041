import React from 'react'
import './Nexity_Stat.css'

function Nexity_Stat() {
  return (
    <section id='nexity_stat'>
        <h1>Des logements de qualité pour un investissement de longue durée</h1>
        <p>Nous mettons tout notre savoir-faire à votre service pour vous proposer l'investissement qui vous correspond.</p>
        <div className='nexity_stat_cards'>
            <div className='nexity_stat-card'>
                <div className='stat-style'>+3000</div>
                <p>Investisseurs accompagnés par an</p>
            </div>

            <div className='nexity_stat-card'>
                <div className='stat-style'>92%</div>
                <p>De nos logements sont équipés d'un espace extérieur</p>
            </div>
  
            <div className='nexity_stat-card'>
                <div className='stat-style'>1er</div>
                <p>acteur de l’immobilier en France</p>
            </div>

            <div className='nexity_stat-card'>
                <div className='stat-style'>1er</div>
                <p>Promoteur Bâtiment Bas Carbone depuis 2018</p>
            </div>
        </div>              
 
    </section>
  )
}

export default Nexity_Stat