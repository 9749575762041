import { React, useState, useEffect } from "react";
import "./Simulateur.scss";
import euro from "../images/euro.svg";
import arrow from "../images/arrow_black.svg";
import retry from "../images/retry.svg";
import Select from "react-select";

function Simulateur(props) {
  const [budget, setBudget] = useState(0);
  const [yearDefiscal, setYearDefiscal] = useState({
    value: 6,
    label: "6 ans",
  });
  const [defiscal1, setDefiscal1] = useState(0);
  const [defiscal2, setDefiscal2] = useState(0);
  const [economy, setEconomy] = useState(0);
  const [hiddencomp, setHiddencomp] = useState(false);
  const [error_compa, setError_compa] = useState(false);

  function hideform() {
    window.dataLayer.push({
      event: "uaevent",
      eventCategory: "Pinel",
      eventAction: "clic-CTA",
      eventLabel: "prendre_rdv",
    });
    props.toggleform();
  }
  function toggle_simu() {
    window.dataLayer.push({
      event: "uaevent",
      eventCategory: "Pinel",
      eventAction: "clic-CTA",
      eventLabel: "validation_budget_simulation",
    });

    if (hiddencomp) {
      setHiddencomp(false);
      const elem = document.getElementById("container_simulateur");
      window.scrollTo(0, elem.offsetTop - 130);
    } else {
      if (
        error_compa === false &&
        document.getElementById("budget").value.length > 0
      ) {
        setHiddencomp(true);
        const elem = document.getElementById("container_simulateur");
        window.scrollTo(0, elem.offsetTop - 130);
      } else {
        const input_budget = document.getElementById("budget");
        input_budget.focus();
      }
    }
  }
  function vw(size) {
    if (props.width > 768) {
      return `min(${((size * 100) / 1440).toString()}vw, ${size}px)`;
    } else {
      return `min(${((size * 100) / 375).toString()}vw, ${size}px)`;
    }
  }

  const options = [
    { value: 6, label: "6 ans" },
    { value: 9, label: "9 ans" },
    { value: 12, label: "12 ans" },
  ];
  function numStr(a, b) {
    a = "" + a;
    b = b || " ";
    var c = "",
      d = 0;
    while (a.match(/^0[0-9]/)) {
      a = a.substr(1);
    }
    for (var i = a.length - 1; i >= 0; i--) {
      c = d != 0 && d % 3 == 0 ? a[i] + b + c : a[i] + c;
      d++;
    }
    return c;
  }

  function focus_simu() {
    const input_budget = document.getElementById("budget");
    input_budget.focus();
    window.dataLayer.push({
      event: "uaevent",
      eventCategory: "Pinel",
      eventAction: "clic-CTA",
      eventLabel: "commencer_la_simulation",
    });
  }

  function defiscalisation() {
    const input_budget = document.getElementById("budget").value;
    setBudget(input_budget);
  }
  useEffect(() => {
    if (hiddencomp === true) {
      document.getElementById("budget_start").value = budget;
      setDefiscal1(() => {
        if (budget > 300000) {
          return 63000;
        } else {
          if (yearDefiscal.value === 6) {
            return Math.round(budget - budget * 0.105);
          }
          if (yearDefiscal.value === 9) {
            return Math.round(budget - budget * 0.15);
          }
          if (yearDefiscal.value === 12) {
            return Math.round(budget - budget * 0.175);
          }
        }
      });
      setDefiscal2(() => {
        if (budget > 300000) {
          return 52500;
        } else {
          if (yearDefiscal.value === 6) {
            return Math.round((budget*0.965) - ((budget*0.965) * 0.105));
          }
          if (yearDefiscal.value === 9) {
            return Math.round((budget*0.965) - ((budget*0.965) * 0.15));
          }
          if (yearDefiscal.value === 12) {
            return Math.round((budget*0.965) - ((budget*0.965) * 0.175));
          }
        }
      });
      setEconomy(defiscal1 - defiscal2);
    } else {
      if ((budget < 100000 && budget > 0) || budget > 300000) {
        setError_compa(true);
      } else {
        setError_compa(false);
      }
    }
  }, [budget, defiscal1, hiddencomp, error_compa, yearDefiscal]);

  const customStyles = {
    inputContainer: (base, state) => ({
      ...base,
      height: "100px",
      width: "100%",
      border: "none",
    }),
    container: (base, state) => ({
      ...base,
      margin: "0 auto",
      borderRadius: 0,
      zIndex: 2,
      width: vw(300),
      height: hiddencomp ? 90 : 100,
      fontSize: 20,
    }),
    singleValue: (base, state) => ({
      ...base,
      fontSize: 26,
      lineHeight: 19,
      fontWeight: "500",
    }),
    option: (base, state) => ({
      ...base,
      color: "black",
      "&:hover": {
        cursor: "pointer",
        border: "none",
        boxShadow: "none",
      },
    }),
    control: (base, state) => ({
      ...base,
      height: "100%",
      border: "none",
      boxShadow: "none",
      "&:focus": {
        border: "none",
        boxShadow: "none",
      },
      "&:hover": {
        cursor: "pointer",
        border: "none",
        boxShadow: "none",
      },
    }),
    placeholder: (base, state) => ({
      ...base,
      fontSize: 42,
      lineHeight: 19,
      fontWeight: "500",
    }),
    valueContainer: (base, state) => ({
      ...base,
      color: "black",
      padding: "0",
      paddingLeft: 16,
      height: "100%",
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      Image: {
        height: 22,
        width: 22,
      },
    }),
    indicatorContainer: (base, state) => ({
      ...base,
    }),
  };
  return (
    <section id="container_simulateur">
      {hiddencomp ? (
        <></>
      ) : (
        <div className="simulateur_home">
          <div className="simulateur_header">
            <div>
              {" "}
              <h1>COMPARATEUR</h1>{" "}
              <h1 className="simulateur_header-pinel">
                <span className="pinel_style">PINEL BOOSTÉ</span>
              </h1>{" "}
            </div>
            <p>
              Découvrez combien vous pouvez économiser sur votre bien grâce au
              Pinel Boosté Nexity
            </p>
            <button onClick={focus_simu} className="btn_simulation">
              <img src={arrow} alt="arrow right" /> Commencer la simulation
            </button>
          </div>

          <div className="simulateur_action">
            <div>
              <p>Quel est votre budget ?</p>
              <div>
                <label for="budget">Budget</label>
                <br />
                <input
                  onInput={defiscalisation}
                  type="number"
                  id="budget"
                  name="budget"
                  min="0"
                  placeholder="Votre montant"
                />
                <img src={euro} alt="euro logo" />
                {error_compa ? (
                  <p id="error_compa">
                    Montant invalide (100 000€ &gt; budget &gt; 300 000€)
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <span>
                <p>Durée en année de défiscalisation</p>
                <Select
                  isSearchable={false}
                  styles={customStyles}
                  options={options}
                  onChange={(value) => {
                    setYearDefiscal(value);
                  }}
                  value={yearDefiscal}
                />
              </span>
            </div>

            <button onClick={toggle_simu} className="grow_spin cta_simulateur">
              Je valide
            </button>
          </div>
        </div>
      )}

      {hiddencomp ? (
        <div className="simulateur_start ">
          <div className="container_fields">
            <div className="calcul_field">
              <p>
                <strong>Comparateur Pinel :</strong>
              </p>
              <div className="budget_start">
                <label for="budget">Budget</label>
                <br />
                <input
                  type="number"
                  id="budget_start"
                  name="budget"
                  min="0"
                  placeholder="Votre montant"
                  disabled
                />
                <img src={euro} alt="euro logo" />
              </div>
            </div>
            <div className="calcul_field">
              <p>Durée en année de défiscalisation</p>
              <Select
                isSearchable={false}
                styles={customStyles}
                options={options}
                onChange={(value) => {
                  setYearDefiscal(value);
                }}
                value={yearDefiscal}
              />
            </div>
            <div className="calcul_field">
              <p>Prix de revient du bien avec Pinel 2023</p>
              <div className="defiscal">
                <p>{numStr(defiscal1)} €</p>
              </div>
            </div>

            <div className="calcul_field">
              <p>Prix de revient du bien avec Pinel Boosté</p>
              <div className="defiscal">
                <p>{numStr(defiscal2)} €</p>
              </div>
            </div>

            <div className="calcul_field">
              <p className="total_economy-label">Soit un gain de</p>
              <div className="total_economy">
                <p>+ {numStr(economy)} €</p>
              </div>
            </div>
          </div>

          <div>
            <button onClick={toggle_simu} className="btn_retry">
              <img src={retry} /> Recommencer
            </button>
            <button
              onClick={hideform}
              className="grow_spin cta_simulateur-start"
            >
              Je prends rendez-vous
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </section>
  );
}

export default Simulateur;
