import { React, useState } from "react";
import Form from "./Form";
import "./Primo2_invest.css";
import arrow_btn from "../images/arrow_carousel.svg";
import inclusive from "../images/inclusive_rotate.svg";

function Primo2_invest(props) {
  function hideform() {
    window.dataLayer.push({
      event: "uaevent",
      eventCategory: "Pinel",
      eventAction: "clic-CTA",
      eventLabel: "decouvrir_loffre",
    });
    props.toggleform();
  }
  return (
    <section id="primo2_invest">
      <div className="container_primo2_header">
        <div className="primoinvest2_header"></div>
        <div className="bginvest_box"></div>
        <div className="primoinvest2_inclusive">
          <img src={inclusive} />
        </div>
      </div>

      <div className="primo2_description">
        <h1>Profitez du Pinel Boosté uniquement jusqu'au 2 avril 2023.</h1>
        <p>
          Et économisez jusqu’à 10 500 €* sur le prix d’achat de votre
          appartement. Exclusivement chez Nexity, et seulement jusqu'au
          02/04/2023.
        </p>
        <button onClick={hideform} className="grow_spin cta_primo2">
          Je profite de l’offre
          <img src={arrow_btn} />
        </button>
      </div>
    </section>
  );
}

export default Primo2_invest;
