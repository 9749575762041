import React from "react";
import "./Boost_graph.scss";
import arrow_btn from "../images/arrow_carousel.svg";

function Boost_graph(props) {
  function hideform() {
    props.toggleform();
  }
  return (
    <section id="boost_graph">
      <div>
        <h2>Obtenez plus grâce au PINEL Boosté</h2>
        <p>
          En 2023, la loi Pinel change et la réduction d’impôt qu’elle permet va
          diminuer sur un grand nombre de logements. Pour palier cela, Nexity
          propose en exclusivité le Pinel Boosté : une offre qui amortit cette
          perte directement dans le prix d'achat de votre bien.
        </p>
        <button onClick={hideform} className="grow_spin cta_graph">
          J'en profite <img className="" src={arrow_btn} alt="arrow" />
        </button>
      </div>
      <div className="graph">
        <div className="graph_container"></div>
        <p className="graph_description">
          *Dont 50 662 € de défiscalisation à taux de 17,5% et 10 500 € de remise
          Nexity.
        </p>
      </div>
    </section>
  );
}

export default Boost_graph;
